/* eslint-disable no-unused-expressions */
import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'
import './card-result.scss'
import { useTranslation } from 'react-i18next'

function getHighlightedText(text, higlight) {
  // Split text on higlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${higlight})`, 'gi'))
  const texted = _.map(parts, (part) => (part.toLowerCase() === higlight.toLowerCase()
    ? `<span class="highlightClass">${part}</span>`
    : part))
  return _.join(texted, '')
}

function getUrl(postType, slug) {

    const { i18n } = useTranslation();

  let url
  switch (postType) {
    case 'page':
      url = slug === 'home' ? `${i18n.language === 'en' ? '' : `/${i18n.language}`}/` : `${i18n.language === 'en' ? '' : `/${i18n.language}`}/${slug}/`
      break
    case 'post':
      url = `${i18n.language === 'en' ? '' : `/${i18n.language}`}/news/${slug}/`
      break

    default:
      url = `${i18n.language === 'en' ? '' : `/${i18n.language}`}/${postType}/${slug}/`
      break
  }
  return url
}

const CardResult = ({ data, search }) => (
  <div className="cardResult__container">
    {data.location && <span> {data.location[0].name}</span>}
    <Link to={getUrl(data.post_type, data.slug)}>
      <h3>{data.title}</h3>
    </Link>
    <div className="cardResult__content" dangerouslySetInnerHTML={{ __html: getHighlightedText(data.excerpt, search) }} />
    <div className="cardResult__tags">
      <span>{data.post_type_label}</span>
    </div>

  </div>
)
export default CardResult
