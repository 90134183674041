import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import queryString from 'query-string'
import encodeUrl from 'encodeurl'
import { navigate } from 'gatsby'
import { AbortController } from 'node-abort-controller'
import makeRequest from '../../helpers/make-request'
import SearchView from './search-view'
import { useTranslation } from 'react-i18next'

const SearchCotroller = ({ location }) => {
  // console.log('🚀 ~ file: search-controller.js:8 ~ SearchCotroller ~ location:', location, queryString.parse(location.search))
  const controller = new AbortController()
  const [loading, setLoading] = useState(true)
  const [dataSearch, setDataSearch] = useState([])
  const [totalPages, setTotalPages] = useState(null)
  const [totalPosts, setTotalPosts] = useState(null)
  const [pageSelect, setPageSelect] = useState(0)
  const [search, setSearch] = useState(null)
  const [filters, setFilters] = useState({
    search: undefined,
    recent: 1,
    page: 1,
  })

  const { i18n } = useTranslation();

  // catch with useEffect so the data will be contained
  const getDataSearch = async () => {
    if (filters.search === undefined) {
      return null
    }
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'search',
      signal: controller.signal,
      params: {
        ...filters,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataSearch(resp.data.posts)
      setTotalPages(resp.data.total_pages)
      setTotalPosts(resp.data.total_posts)
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const filtersMeno = useMemo(
    () => ({
      search: filters.search,
      recent: filters.recent,
      page: filters.page,
    }), [filters.search,
    filters.recent,
    filters.page,
  ],
  )
  const onCleanResult = () => {
    navigate(`${i18n.language === 'en' ? '' : `/${i18n.language}`}/search/`)
    setSearch(null)
    setDataSearch(null)
    setFilters(() => ({
      search: undefined,
      recent: 1,
      page: 1,
    }))
  }

  useEffect(() => {
    getDataSearch()
    return () => {
      controller.abort()
    }
  }, [filtersMeno])

  const onHandleFilterByQuery = (value) => {
    const newvalue = _.replace(value, '&', '%26')
    // console.log('🚀 ~ file: search-controller.js:81 ~ onHandleFilterByQuery ~ value:', newvalue)
    navigate(`${i18n.language === 'en' ? '' : `/${i18n.language}`}/search/?search=${newvalue}`)
  }

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      const { search: value } = queryString.parse(location.search)

      const onFilterByQuery = () => {
        if (value === undefined) return
        let updatedValue = {}
        updatedValue = {
          search: value,
        }
        setFilters((oldState) => ({
          ...oldState,
          ...updatedValue,
        }))
        setSearch(value)
      }

      onFilterByQuery()
    }
  }, [location])

  const onHandleFilterSort = (value) => {
    let updatedValue = {}
    updatedValue = {
      recent: value,
    }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const onHandleNextPage = (value) => {
    setPageSelect(value - 1)
    let updatedValue = {}
    updatedValue = {
      page: value,
    }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  // const debouncedChangeHandler = useCallback(
  //   debounce(onHandleFilterByQuery, 500),
  //   [],
  // )

  const viewProps = {
    data: dataSearch,
    loading,
    search,
    onCleanResult,
    onHandleFilterByQuery,
    onHandleFilterSort,
    onHandleNextPage,
    filters,
    totalPages,
    totalPosts,
    pageSelect,
  }
  return (
    <SearchView {...viewProps} />
  )
}

export default SearchCotroller
