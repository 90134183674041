import { t } from 'i18next'
import _ from 'lodash'
import React, { useState, useRef } from 'react'
import IconSearch from '../../assets/icons/seasongroup_icons_search.svg'
import IconClose from '../../assets/icons/seasongroup_icons_plus.svg'
import { Spinner, GradientBanner } from '../../components'
import { ListCardResult } from './components'
import './search-styles.scss'

const TitleSearch = ({
  search, data, totalPosts, loading,
}) => {
  let title
  switch (true) {
    case _.isEmpty(search) && _.isEmpty(data):
      title = <h2>{t('search.title')}</h2>
      break
    case _.isEmpty(data) && !loading:
      title = <h2>{t('search.afterSearchTitle', { number: 0, search })}</h2>
      break
    case !loading:
      title = <h2>{t('search.afterSearchTitle', { number: totalPosts, search })}</h2>
      break
    default:
      title = <h2></h2>
      break
  }

  return title
}

const SearchView = ({
  data,
  loading,
  search,
  onCleanResult,
  onHandleFilterSort,
  onHandleFilterByQuery,
  filters,
  totalPages,
  totalPosts,
  onHandleNextPage,
  pageSelect,
}) => {
  const [paramSearch, setParamSearch] = useState(null)
  const refElemnet = useRef(null)
  const onHandleSearched = (e) => {
    setParamSearch(e.target.value)
  }

  const onHandleCleanResult = () => {
    onCleanResult()
    setParamSearch(null)
    refElemnet.current.value = null
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onHandleFilterByQuery(paramSearch)
    }
  }

  return (
    <>
      <div className="search__container">
        <div>
          <div className="page-title">
            <TitleSearch search={search} data={data} totalPosts={totalPosts} loading={loading} />
          </div>
          <div className="search__search">
            <div className="search__search__box">
              <input ref={refElemnet} onChange={onHandleSearched} type="text" placeholder={t('search.placeholder')} onKeyPress={handleKeyPress} />
              {
                !_.isEmpty(search) && (
                  <span onClick={onHandleCleanResult}>
                    <IconClose width="20" fill="#9E9E9E" />
                  </span>

                )
              }
            </div>
            <div onClick={() => onHandleFilterByQuery(paramSearch)} className="search__search__lens">
              <IconSearch width="20" fill="#0015E0" />
            </div>
          </div>
          {
            data ? (
              <div className="search__filters">
                <p>{t('search.filter.text')}</p>
                <p className={filters.recent === 0 ? 'search__filters__click active' : 'search__filters__click '} onClick={() => onHandleFilterSort(0)}>{t('search.filter.relevant')}</p>
                <span>|</span>
                <p className={filters.recent === 1 ? 'search__filters__click active' : 'search__filters__click '} onClick={() => onHandleFilterSort(1)}>{t('search.filter.recent')}</p>
              </div>
            ) : null
          }
        </div>

        {
          !loading
            ? <ListCardResult
              t={t}
              data={data}
              search={search}
              totalPosts={totalPosts}
              onHandleNextPage={onHandleNextPage}
              totalPages={totalPages}
              pageSelect={pageSelect}
            />
            : <Spinner />
        }

      </div>

      <GradientBanner />
    </>

  )
}

export default SearchView
